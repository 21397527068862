import { Component, OnInit } from '@angular/core';
import { RouterLink } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { CookieService } from 'ngx-cookie-service';

@Component({
  selector: 'app-cookies',
  standalone: true,
  imports: [TranslateModule, RouterLink],
  templateUrl: './cookies.component.html',
  styleUrl: './cookies.component.scss',
})
export class CookiesComponent implements OnInit {
  public isCookiesAccepted!: boolean;

  constructor(private cookie: CookieService) {}

  ngOnInit(): void {
    if (this.cookie.check('status')) {
      this.isCookiesAccepted = true;
    } else {
      this.isCookiesAccepted = false;
    }
  }
  closeCookies() {
    this.cookie.set('status', 'accepted');
    this.isCookiesAccepted = this.cookie.check('status');
  }
}
