import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ModalService {
  private _modal: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  constructor() { }

  get modal() {
    return this._modal.asObservable();
  }

  showModal() {
    this._modal.next(true);
  }

  hideModal() {
    this._modal.next(false);
  } 

}
