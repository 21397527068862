import { Injectable } from '@angular/core';
import { Subject, BehaviorSubject } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
  providedIn: 'root',
})
export class LanguageService {
  public _languageChanged = new BehaviorSubject<string>('');

  public actualLanguage: string = '';

  constructor(private translate: TranslateService) {
    this.initLocalStorage();
  }

  private initLocalStorage() {
    if (typeof localStorage !== 'undefined') {
      const savedLanguage = localStorage.getItem('language');
      this.actualLanguage = savedLanguage ? savedLanguage : '';
      this._languageChanged.next(this.actualLanguage);
    }
  }

  get languageChanged() {
    return this._languageChanged.asObservable();
  }

  changeLanguage(language: string) {
    this.translate.setDefaultLang(language);
    this.translate.use(language);
    this.actualLanguage = language;

    if (typeof localStorage !== 'undefined') {
      localStorage.setItem('language', language);
    }

    this._languageChanged.next(language);
  }
}
