@if (!isCookiesAccepted) {
<section
  class="bg-ayr-black-opacity-90 p-9 fixed bottom-0 left-0 w-full text-white z-50"
>
  <div class="max-w-960 m-auto flex justify-between items-center">
    <p>
      {{ "footer.cookies.cookies-cookies" | translate
      }}<a
        routerLink="/privacy-policy"
        class="cursor-pointer text-ayr-green-500 underline"
        >{{ "footer.cookies.cookies-policy" | translate }}</a
      >.
    </p>

    <button
      class="bg-ayr-green-500 hover:bg-ayr-green-800 rounded-full px-4 py-2"
      (click)="closeCookies()"
    >
      {{ "footer.cookies.button" | translate }}
    </button>
  </div>
</section>
}
