import { CommonModule, isPlatformBrowser } from '@angular/common';
import { Component, Inject, PLATFORM_ID, inject } from '@angular/core';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { LanguageService } from '../../../services/language.service';
import { DarkModeService } from '../../../services/darkmode.service';
import { NavigationEnd, Router, RouterModule } from '@angular/router';
import { ScrollToTopComponent } from './../../../components/scroll-to-top/scroll-to-top.component';
import { filter } from 'rxjs';

@Component({
  selector: 'app-footer',
  standalone: true,
  templateUrl: './footer.component.html',
  imports: [CommonModule, RouterModule, TranslateModule, ScrollToTopComponent],

  styleUrl: './footer.component.scss',
})
export class FooterComponent {
  platformID = inject(PLATFORM_ID);
  isDarkMode: boolean = false;
  email = 'ayr@ceiia.com';
  public year!: number;
  constructor(
    @Inject(TranslateService) private translateService: TranslateService,
    private languageService: LanguageService,
    private darkModeService: DarkModeService,
    public router: Router
  ) {}

  ngOnInit(): void {
    if (isPlatformBrowser(this.platformID)) {
      this.year = this.getCurrentYear();
      this.darkModeService.isDarkMode.subscribe((enabled) => {
        this.isDarkMode = enabled;
      });
    }

    this.router.events
      .pipe(filter((event) => event instanceof NavigationEnd))
      .subscribe(() => {
        window.scrollTo(0, 0); // Scroll to the top of the page
      });
  }

  getCurrentYear(): number {
    var currentTime = new Date();
    return currentTime.getFullYear();
  }
}
