<nav class="relative navbar py-2 px-4 lg:px-0 h-14 w-full z-50 bg-white flex justify-center dark:bg-black">
    <div class="max-w-screen-xl lg:px-32 w-full flex justify-between gap-4">
        <div class="flex flex-1 items-center w-full justify-center lg:justify-end">
            <div class="flex flex-shrink-0 items-center lg:-ml-4 mr-auto">
                <img src="https://storage.googleapis.com/weayr-assets-non-prod/images/sidebar/images/ayr-logo.svg" width="100px" height="40px" loading="lazy"
                    alt="Black AYR logo" class="cursor-pointer w-min h-10 px-2 lg:px-4 py-2 lg:py-1 rounded lg:hover:bg-[#F4F4F4] lg:dark:hover:bg-[#0F0F0F]" *ngIf="!isDarkMode" data-toggle="tooltip"
                    data-placement="right" title="{{ 'navbar.menu.tooltip.home' | translate }}" 
                    routerLink="/home" />
                <img src="https://storage.googleapis.com/weayr-assets-non-prod/images/sidebar/images/ayr-logo white.svg" width="100px" height="40px" loading="lazy"
                    alt="White AYR logo" class="cursor-pointer w-min h-10 px-2 lg:px-4 py-2 lg:py-1 rounded lg:hover:bg-[#F4F4F4] lg:dark:hover:bg-[#0F0F0F]" *ngIf="isDarkMode" data-toggle="tooltip"
                    data-placement="right" title="{{ 'navbar.menu.home' | translate }}" routerLink="/home" />
            </div>
            <div class="hidden lg:block h-10">
                <div class="flex gap-2 h-10">
                    <ng-container *ngFor="let item of navigationItems">
                        <a [routerLink]="item.route" role="link" routerLinkActive="active-link"
                            data-toggle="tooltip" data-placement="right" title="{{ item.name | translate}}"
                            class="tracking-wide leading-6 dark:text-white px-4 py-2 font-semibold rounded hover:bg-[#F4F4F4] dark:hover:bg-[#0F0F0F]"
                            [ngClass]="{'selected-nav-item dark:!text-[#0bcf9e] text-[#066B51] !bg-[#0BCF9E]/10': router.url.includes(item.route.split('/')[1])}"
                            (click)="closeNavbar()">
                            <span class="navigation-item">{{ item.name | translate}}</span>
                        </a>
                    </ng-container>
                </div>
            </div>
        </div>
        <div class="lg:hidden absolute bg-white dark:bg-black" id="mobile-menu" [ngClass]="{'nav-active': navbarOpen}">
            <div class="px-4 py-2 border-t border-b border-zinc-200 dark:border-zinc-900">
                <ng-container *ngFor="let item of navigationItems">
                    <a [routerLink]="item.route" role="link" routerLinkActive="active-link" data-toggle="tooltip"
                        data-placement="right" title="{{ item.name | translate}}"
                        class="text-left block leading-6 tracking-wider dark:text-white px-2 py-2 font-semibold rounded"
                        [ngClass]="{'selected-nav-item dark:!text-[#0bcf9e] text-[#066B51] bg-[#0BCF9E]/10': router.url.includes(item.route.split('/')[1])}"
                        (click)="closeNavbar()">
                        <span class="navigation-item">{{ item.name | translate}}</span>
                    </a>
                </ng-container>
            </div>
        </div>
        <div class="flex items-center justify-end gap-4">
            <button type="button" (click)="toggleDarkMode()" class="flex items-center justify-center h-10 w-10 rounded hover:bg-[#F4F4F4] dark:hover:bg-[#0F0F0F] pt-[1px]">
                <img src="https://storage.googleapis.com/weayr-assets-non-prod/images/sidebar/icons/light_mode.svg"
                    *ngIf="!isDarkMode" data-toggle="tooltip" data-placement="right" class="btn-mode w-4 h-4" height="24px" width="24px" loading="lazy"
                    alt="Change to {{ 'navbar.menu.light-mode' | translate }}"
                    title="{{ 'navbar.menu.dark-mode' | translate }}" />
                <img src="https://storage.googleapis.com/weayr-assets-non-prod/images/sidebar/icons/dark_mode.svg"
                    *ngIf="isDarkMode" data-toggle="tooltip" data-placement="right" class="btn-mode w-4 h-4" height="24px" width="24px" loading="lazy"
                    alt="Change to {{ 'navbar.menu.light-mode' | translate }}"
                    title="{{ 'navbar.menu.light-mode' | translate }}" />
            </button>
            <div class="relative">
                <button type="button" data-toggle="tooltip" data-placement="right"
                    title="{{ 'LANGUAGE' | translate }}" aria-label="language change"
                    class="navigation-item change-language flex items-center justify-center h-10 w-10 rounded hover:bg-[#F4F4F4] dark:hover:bg-[#0F0F0F]" (click)="openLanguageModal()" #languageButton>
                    <span
                        class="font-semibold tracking-wider leading-6 dark:text-white">{{
                        getLanguageShortCode() }}</span>
                </button>
                <div class="absolute right-0 lg:left-0 z-10 mt-2 w-fit origin-top-right rounded bg-white dark:bg-black p-2 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none flex flex-col"
                    aria-live="polite" role="menu" aria-orientation="vertical" aria-labelledby="user-menu-button"
                    tabindex="-1" *ngIf="showLanguageDropdown">
                    <ng-container *ngFor="let language of languages; let i = index;" class="">
                        <div class="cursor-pointer h-10" (click)="onSelectLanguage(i)" [ngClass]="{'pointer-events-none': language.selected}"
                            (keydown.enter)="onSelectLanguage(i)">
                            <span class="navigation-item text-base font-semibold flex items-center h-full px-2 rounded hover:bg-[#F4F4F4] dark:hover:bg-[#0F0F0F] w-full" title="{{language.translate
                                    | translate}}"
                                [ngClass]="{'text-ayr-black dark:text-white': !language.selected, 'text-zinc-500 bg-zinc-100 dark:bg-zinc-950': language.selected}">{{language.translate
                                | translate}}</span>
                        </div>
                    </ng-container>
                </div>
            </div>
            <button type="button"
                class="h-10 w-10 lg:hidden relative inline-flex items-center justify-center rounded text-black dark:text-white hover:bg-[#F4F4F4] dark:hover:bg-[#0F0F0F] focus:outline-none pt-[1px]"
                aria-label="menu" aria-controls="mobile-menu" aria-expanded="false" (click)="toggleNavbar()">
                <span class="absolute"></span>
                <svg class="block h-6 w-6" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor"
                        aria-hidden="true">
                        <path stroke-linecap="round" stroke-linejoin="round"
                            d="M3.75 6.75h16.5M3.75 12h16.5m-16.5 5.25h16.5" />
                    </svg>
                    <svg class="hidden h-5 w-5" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor"
                    aria-hidden="true">
                    <path stroke-linecap="round" stroke-linejoin="round" d="M6 18L18 6M6 6l12 12" />
                </svg>
            </button>
        </div>
    </div>
</nav>
