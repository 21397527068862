import {
  Component,
  HostListener,
  Inject,
  PLATFORM_ID,
  TransferState,
  inject,
  makeStateKey,
} from '@angular/core';
import { Router, RouterModule } from '@angular/router';
import { LanguageService } from '../../../services/language.service';
import { NavbarService } from '../../../services/navbar-service';
import { CommonModule, isPlatformBrowser } from '@angular/common';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { FormsModule } from '@angular/forms';
import { ModalService } from '../../../services/modal.service';
import { MatDialog } from '@angular/material/dialog';
import { NavigationItem } from '../../../models/navigation';
import { DarkModeService } from '../../../services/darkmode.service';

@Component({
  selector: 'app-navbar',
  standalone: true,
  imports: [
    CommonModule,
    TranslateModule,
    FormsModule,
    RouterModule,
    // AngularSvgIconModule,
  ],
  templateUrl: './navbar.component.html',
  styleUrl: './navbar.component.scss',
})
export class NavbarComponent {
  platformID = inject(PLATFORM_ID);
  transferState = inject(TransferState);
  languages: any[] = [
    { value: 'pt-PT', translate: 'navbar.menu.portuguese', selected: true },
    { value: 'en-US', translate: 'navbar.menu.english', selected: false },
  ];
  selectedLanguage!: string;
  showLanguageDropdown: boolean = false;
  isDarkMode: boolean = false;
  public enabled: boolean = false;
  public navbarOpen = false;
  public innerWidth: any;
  public mode!: string;
  navigationItems!: any;
  dataKeyNavigationItems = makeStateKey<NavigationItem[]>('navigationItems');
  constructor(
    @Inject(TranslateService) private translateService: TranslateService,
    private languageService: LanguageService,
    private darkModeService: DarkModeService,
    private dialog: MatDialog,
    private modalService: ModalService,
    private navbarService: NavbarService,
    public router: Router
  ) {
    if (isPlatformBrowser(this.platformID)) {
      this.selectedLanguage = localStorage.getItem('language') || 'en-US';
    }
  }

  @HostListener('window:resize')
  onResize() {
    this.innerWidth = window.innerWidth;
  }

  ngOnInit(): void {
    if (isPlatformBrowser(this.platformID)) {
      this.darkModeService.isDarkMode.subscribe((enabled) => {
        this.isDarkMode = enabled;
      });

      const language = localStorage.getItem('language');
      if (language) {
        this.languages.map((lang) => {
          if (lang.value === language) {
            lang.selected = true;
          } else {
            lang.selected = false;
          }
        });
      }

      this.innerWidth = window.innerWidth;

      this.navigationItems = this.transferState.get<NavigationItem[]>(
        this.dataKeyNavigationItems,
        []
      );
    }
  }

  toggleDarkMode(): void {
    this.darkModeService.toggleDarkMode(!this.isDarkMode);
  }

  onSelectLanguage(i: number) {
    if (isPlatformBrowser(this.platformID)) {
      const selectedLanguage = this.languages[i].value;
      this.setLanguage(selectedLanguage);
      this.showLanguageDropdown = false;
    }
  }

  private setLanguage(language: string) {
    if (isPlatformBrowser(this.platformID)) {
      this.languages.forEach((lang) => {
        lang.selected = lang.value === language;
      });

      localStorage.setItem('language', language);
      this.languageService.changeLanguage(language);
      this.translateService.setDefaultLang(language);
      this.translateService.use(language);
    }
  }

  getLanguageShortCode(): string {
    return this.languageService.actualLanguage === 'en-US'
      ? 'EN'
      : this.languageService.actualLanguage === 'pt-PT'
      ? 'PT'
      : '';
  }

  openLanguageModal() {
    this.showLanguageDropdown = !this.showLanguageDropdown;
  }

  toggleNavbar() {
    this.navbarOpen = !this.navbarOpen;
  }

  closeNavbar() {
    this.navbarOpen = false;
  }
}
