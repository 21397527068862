import {
  Inject,
  Injectable,
  PLATFORM_ID,
  TransferState,
  inject,
  makeStateKey,
} from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { Style } from '../models/style';
import { NavigationItem } from '../models/navigation';
import { isPlatformBrowser, isPlatformServer } from '@angular/common';

@Injectable({
  providedIn: 'root',
})
export class NavbarService {
  transferState = inject(TransferState);
  private darkModeActivate = new BehaviorSubject<boolean>(false);
  currentMode = this.darkModeActivate.asObservable();
  dataKeyNavigationItems = makeStateKey<NavigationItem[]>('navigationItems');

  navigationItems: NavigationItem[] = [
    new NavigationItem('Home', 'navbar.menu.home', '/home'),
    new NavigationItem('Citizens', 'navbar.menu.citizens', '/citizens'),
    new NavigationItem('Cities', 'navbar.menu.cities', '/cities'),
    new NavigationItem('Businesses', 'navbar.menu.businesses', '/businesses'),
    new NavigationItem('About', 'navbar.menu.about', '/about'),
  ];

  constructor(@Inject(PLATFORM_ID) private platformId: Object) {
    if (isPlatformServer(this.platformId)) {
      this.transferState.set<NavigationItem[]>(
        this.dataKeyNavigationItems,
        this.navigationItems
      );
    }
  }

  changeMode(darkMode: boolean) {
    this.darkModeActivate.next(darkMode);
  }

  getStyle(
    styleString: Style,
    originalColor: string,
    newColor: string,
    darkMode: boolean
  ): string {
    switch (styleString) {
      case Style.BACKGROUND_COLOR:
        if (darkMode) {
          return `background-color: ${newColor}`;
        }
        return `background-color: ${originalColor}`;

      case Style.TEXT_COLOR:
        if (darkMode) {
          return `color: ${newColor}`;
        }
        return `color: ${originalColor}`;
      default:
        return '';
    }
  }
}
