import { Routes } from '@angular/router';
import { LayoutComponent } from './shared/layout/layout.component';

export const routes: Routes = [
  {
    path: '',
    redirectTo: 'home',
    pathMatch: 'full',
  },
  {
    path: 'home',
    component: LayoutComponent,
    children: [
      {
        path: '',
        loadComponent: () =>
          import('./pages/home/home.component').then(m => m.HomeComponent),
      },
    ],
  },
  {
    path: 'about',
    component: LayoutComponent,
    children: [
      {
        path: '',
       loadComponent: () => 
        import('./pages/about/about.component').then(m => m.AboutComponent),
      },
    ],
  },
  {
    path: 'citizens',
    component: LayoutComponent,
    children: [
      {
        path: '',
        loadComponent: () => 
          import('./pages/citizens/citizens.component').then(m => m.CitizensComponent),
      },
    ],
  },
  {
    path: 'cities',
    component: LayoutComponent,
    children: [
      {
        path: '',
         loadComponent: () =>
          import('./pages/cities/cities.component').then(m => m.CitiesComponent),
      },
    ],
  },
  {
    path: 'privacy-policy',
    component: LayoutComponent,
    children: [
      {
        path: '',
         loadComponent: () =>
          import('./pages/privacy-policy/privacy-policy.component').then(m => m.PrivacyPolicyComponent),
      },
    ],
  },
  {
    path: 'frequently-asked-questions',
    component: LayoutComponent,
    children: [
      {
        path: '',
        loadComponent: () =>
          import('./pages/frequently-asked-questions/frequently-asked-questions.component').then(m => m.FrequentlyAskedQuestionsComponent),
      },
    ],
  },
  {
    path: 'terms-and-conditions',
    component: LayoutComponent,
    children: [
      {
        path: '',
        loadComponent: () =>
          import('./pages/terms-and-conditions/terms-and-conditions.component').then(m => m.TermsAndConditionsComponent),
      },
    ],
  },
  {
    path: 'businesses',
    component: LayoutComponent,
    children: [
      {
        path: '',
       loadComponent: () =>
          import('./pages/business/business.component').then(m => m.BusinessComponent),
      },
    ],
  }, 
];
