<section class="relative h-[60dvh] max-h-[520px] lg:h-auto">
  <div class="absolute top-0 left-0 w-full flex justify-center bg-[#f2f2dfcc] dark:bg-[#1A1A1ACC]">
    <div class="w-full grid gap-8 items-center max-w-screen-xl lg:px-32 md:px-12 md:py-16 justify-center py-12 px-6 text-ayr-black md:flex dark:text-white">
      <div class="mr-auto items-center align-middle max-md:text-center max-md:mx-auto">
        <p class="text-lg lg:text-xl mb-2">
          More than just an app.
        </p>
        <p class="text-2xl lg:text-4xl xl:text-[2.65rem] xl:leading-10">
          <span>It's a tool to build a </span>
          <span class="text-bold flex flex-wrap green-highlight">
            <span class="text-shadow">carbon-zero </span>
            <span class="text-shadow">&nbsp;world.</span>
          </span>
        </p>
      </div>
      <div class="gap-4 flex lg:grid grid-cols-2 md:justify-items-end">
        <a
          href="https://apps.apple.com/pt/app/ayr-are-you-ready/id1464716345?l=en"
          role="link"
          aria-label="Download AYR app for iOS on App Store"
          target="_blank"
          data-toggle="tooltip"
          data-placement="right"
          title="Apple store"
          class="pointer float-right bg-ayr-black hover:bg-zinc-900 px-4 py-2 col-span-1 rounded-lg flex justify-center max-md:w-fit"
        >
          <img
            src="https://storage.googleapis.com/weayr-assets-non-prod/images/home/Group 27 white.svg"
            width="162px"
            height="42px"
            loading="lazy"
            class="h-6 md:h-8 w-fit"
            alt="Download AYR app for iOS on App Store"
          />
        </a>

        <a
          href="https://play.google.com/store/apps/details?id=com.ceiia.ayr&hl=en&gl=PT"
          role="link"
          target="_blank"
          aria-label="Download AYR app for Android on Google Play"
          data-toggle="tooltip"
          data-placement="right"
          title="Google Play"
          class="pointer float-right bg-ayr-black hover:bg-zinc-900 px-4 py-2 col-span-1 rounded-lg flex justify-center max-md:w-fit"
        >
          <img
            src="https://storage.googleapis.com/weayr-assets-non-prod/images/home/Group 59 white.svg"
            width="162px"
            height="46px"
            loading="lazy"
            class="h-6 md:h-8 w-fit"
            alt="Download AYR app for Android on Google Play"
          />
        </a>
      </div>
    </div>
  </div>
  <div class="image city-transit-image bg-[#F2F2DF] dark:bg-[#5D5D5D] h-full">
    <picture class="h-full" *ngIf="isDarkMode; else lightMode">
      <source
        media="(min-width: 1024px)"
        srcset="
          https://storage.googleapis.com/weayr-assets-non-prod/images/footer/ayr_footercity_1900_darkmode@1x.avif 1920w,
          https://storage.googleapis.com/weayr-assets-non-prod/images/footer/ayr_footercity_1900_darkmode@1x.webp 1920w,
          https://storage.googleapis.com/weayr-assets-non-prod/images/footer/ayr_footercity_1600_darkmode@1x.avif 1440w,
          https://storage.googleapis.com/weayr-assets-non-prod/images/footer/ayr_footercity_1600_darkmode@1x.webp 1440w,
          https://storage.googleapis.com/weayr-assets-non-prod/images/footer/ayr_footercity_1200_darkmode@1x.avif 1024w,
          https://storage.googleapis.com/weayr-assets-non-prod/images/footer/ayr_footercity_1200_darkmode@1x.webp 1024w,
          https://storage.googleapis.com/weayr-assets-non-prod/images/footer/ayr_footercity_768_darkmode@1x.avif   640w,
          https://storage.googleapis.com/weayr-assets-non-prod/images/footer/ayr_footercity_768_darkmode@1x.webp   640w
        "
        sizes="(max-width: 640px) 640px,
                           (max-width: 1024px) 1024px,
                           (max-width: 1440px) 1440px,
                           1920px"
        type="image/avif"
      />
      <img
        class="h-full max-h-[520px] w-full object-cover object-[50%_bottom]"
        src="https://storage.googleapis.com/weayr-assets-non-prod/images/footer/ayr_footercity_768_darkmode@1x.webp"
        alt="ayr_footercity"
        loading="lazy"
      />
    </picture>
    <ng-template #lightMode>
      <picture class="h-full">
        <source
          media="(min-width: 1024px)"
          srcset="
            https://storage.googleapis.com/weayr-assets-non-prod/images/footer/ayr_footercity_1900_lightmode@1x.avif 1920w,
            https://storage.googleapis.com/weayr-assets-non-prod/images/footer/ayr_footercity_1900_lightmode@1x.webp 1920w,
            https://storage.googleapis.com/weayr-assets-non-prod/images/footer/ayr_footercity_1600_lightmode@1x.avif 1440w,
            https://storage.googleapis.com/weayr-assets-non-prod/images/footer/ayr_footercity_1600_lightmode@1x.webp 1440w,
            https://storage.googleapis.com/weayr-assets-non-prod/images/footer/ayr_footercity_1200_lightmode@1x.avif 1024w,
            https://storage.googleapis.com/weayr-assets-non-prod/images/footer/ayr_footercity_1200_lightmode@1x.webp 1024w,
            https://storage.googleapis.com/weayr-assets-non-prod/images/footer/ayr_footercity_768_lightmode@1x.avif   640w,
            https://storage.googleapis.com/weayr-assets-non-prod/images/footer/ayr_footercity_768_lightmode@1x.webp   640w
          "
          sizes="(max-width: 640px) 640px,
                               (max-width: 1024px) 1024px,
                               (max-width: 1440px) 1440px,
                               1920px"
          type="image/avif"
        />
        <img
          class="h-full w-full object-cover"
          src="https://storage.googleapis.com/weayr-assets-non-prod/images/footer/ayr_footercity_768_lightmode@1x.webp"
          alt="ayr_footercity"
          loading="lazy"
        />
      </picture>
    </ng-template>
  </div>
</section>

<section class="relative">
  <footer class="bg-white dark:bg-black py-8">
    <div class="mx-auto w-full max-w-screen-xl text-center">
      <div class="grid grid-cols-1 gap-8 px-4 py-6 lg:py-8 md:grid-cols-3">
        <div>
          <h2
            class="mb-6 text-sm font-semibold text-ayr-black capitalize dark:text-white"
          >
            {{ "footer.about.about-ayr" | translate }}
          </h2>
          <ul class="text-gray-500 dark:text-gray-200 font-medium">
            <li class="mb-4">
              <a href="#" class="pointer-events-none">
                {{ "footer.about.careers" | translate }}
              </a>
            </li>
            <li class="mb-4">
              <a href="#" class="pointer-events-none">
                {{ "footer.about.resources" | translate }}
              </a>
            </li>
            <li class="mb-4">
              <a
                [routerLink]="['/', 'frequently-asked-questions']"
                class="hover:font-bold hover:text-ayr-black dark:hover:text-white"
              >
                {{ "footer.about.faq" | translate }}
              </a>
            </li>
          </ul>
        </div>
        <div>
          <h2
            class="mb-6 text-sm font-semibold text-ayr-black capitalize dark:text-white"
          >
            {{ "footer.legal.legal" | translate }}
          </h2>
          <ul class="text-gray-500 dark:text-gray-200 font-medium">
            <li class="mb-4">
              <a
                [routerLink]="['/', 'terms-and-conditions']"
                class="hover:font-bold hover:text-ayr-black dark:hover:text-white"
              >
                {{ "footer.legal.terms" | translate }}
              </a>
            </li>
            <li class="mb-4">
              <a
                [routerLink]="['/', 'privacy-policy']"
                class="hover:font-bold hover:text-ayr-black dark:hover:text-white"
              >
                {{ "footer.legal.privacy" | translate }}
              </a>
            </li>
          </ul>
        </div>
        <div>
          <h2
            class="mb-6 text-sm font-semibold text-ayr-black capitalize dark:text-white"
          >
            {{ "footer.contact" | translate }}
          </h2>
          <ul class="text-ayr-black dark:text-gray-400 font-medium">
            <li class="mb-4">
              <a
                href="mailto: ayr@ceiia.com"
                class="text-3xl text-bold no-underline"
                >{{ email }}</a
              >
            </li>
          </ul>
        </div>
        <div class="flex mx-auto justify-center items-center">
          <a
            href="https://www.ceiia.com/"
            role="link"
            target="_blank"
            data-toggle="tooltip"
            data-placement="right"
            title="CEiiA homepage"
            aria-label="CEiiA homepage"
            class="pointer ml-auto"
          >
            <img
              src="https://storage.googleapis.com/weayr-assets-non-prod/images/home/Group 9.svg"
              width="101px"
              height="41px"
              loading="lazy"
              alt="Navigate to ceiia homepage"
              *ngIf="!isDarkMode"
            />
            <img
              src="https://storage.googleapis.com/weayr-assets-non-prod/images/home/Group 9 white.svg"
              width="101px"
              height="41px"
              loading="lazy"
              alt="Navigate to ceiia homepage"
              *ngIf="isDarkMode"
            />
          </a>
        </div>
        <div
          class="flex mt-14 mx-auto sm:justify-center md:mt-0 space-x-7 rtl:space-x-reverse"
        >
          <a
            href="https://www.facebook.com/centrodeengenhariaedesenvolvimento"
            class="text-ayr-black dark:text-white"
            title="CEiiA Facebook"
          >
            <svg
              class="w-7 h-7 mt-0.5"
              aria-hidden="true"
              xmlns="http://www.w3.org/2000/svg"
              fill="currentColor"
              viewBox="0 0 8 19"
            >
              <path
                fill-rule="evenodd"
                d="M6.135 3H8V0H6.135a4.147 4.147 0 0 0-4.142 4.142V6H0v3h2v9.938h3V9h2.021l.592-3H5V3.591A.6.6 0 0 1 5.592 3h.543Z"
                clip-rule="evenodd"
              />
            </svg>
          </a>
          <a
            href="https://www.linkedin.com/company/ceiia---center-for-innovation-and-creative-engineering/mycompany/"
            class="text-ayr-black dark:text-white"
            title="CEiiA LinkedIn"
          >
            <svg
              class="w-8 h-8"
              aria-hidden="true"
              xmlns="http://www.w3.org/2000/svg"
              fill="currentColor"
              viewBox="0 0 24 24"
            >
              <path
                d="M19 0h-14c-2.761 0-5 2.239-5 5v14c0 2.761 2.239 5 5 5h14c2.762 0 5-2.239 5-5v-14c0-2.761-2.238-5-5-5zm-11 19h-3v-11h3v11zm-1.5-12.268c-.966 0-1.75-.79-1.75-1.764s.784-1.764 1.75-1.764 1.75.79 1.75 1.764-.783 1.764-1.75 1.764zm13.5 12.268h-3v-5.604c0-3.368-4-3.113-4 0v5.604h-3v-11h3v1.765c1.396-2.586 7-2.777 7 2.476v6.759z"
              />
            </svg>
          </a>
          <a
            href="https://www.youtube.com/c/CEiiAChannel"
            class="text-ayr-black dark:text-white"
            title="YouTube channel"
          >
            <svg
              class="w-8 h-8"
              aria-hidden="true"
              xmlns="http://www.w3.org/2000/svg"
              fill="currentColor"
              viewBox="0 0 24 24"
            >
              <path
                d="M19.615 3.184c-3.604-.246-11.631-.245-15.23 0-3.897.266-4.356 2.62-4.385 8.816.029 6.185.484 8.549 4.385 8.816 3.6.245 11.626.246 15.23 0 3.897-.266 4.356-2.62 4.385-8.816-.029-6.185-.484-8.549-4.385-8.816zm-10.615 12.816v-8l8 3.993-8 4.007z"
              />
            </svg>
          </a>
        </div>
      </div>
      <div class="mx-auto w-full max-w-screen-xl text-center mb-8">
        <div
          class="block text-sm text-ayr-black text-center dark:text-white mb-4"
        >
          <p class="inline">
            {{ "footer.made-with" | translate }}
          </p>
          <span class="heart-emoji" title="{{ 'footer.love' | translate }}">
            💚
          </span>
          {{ "footer.in-portugal" | translate }}
        </div>

        <div class="text-sm text-ayr-black sm:text-center dark:text-white">
          © Copyright {{ year }}. {{ "footer.all-rights" | translate }}
        </div>
        <div class="w-full flex items-center justify-end pr-16">
          <button-scroll-to-top></button-scroll-to-top>
        </div>
      </div>
    </div>
  </footer>
</section>
